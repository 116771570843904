import {manageButtonDepartureArrival} from "journey_bundle/modules/buttonDepartureArrival";

/**
 * if you dont want to trigger manually everywhere use mutation observer on hidden input
 */
JKisio(document).ready(() => {
    window.addEventListener("journey_input_address_changed", (event) => {
        let fromInput = document.querySelector("#search_from_autocomplete");
        let toInput = document.querySelector("#search_to_autocomplete");

        if(fromInput.value !== "" && toInput.value !== "") { // autocomplete will be verified by submit validator :
            document.querySelector('#journey-form-submit [type="submit"], #journey-form-new-search [type="submit"]').click();
        }
    });

});

