import { manageGeoMarkers } from 'pnp_core_bundle/modules/map-popup';

/**
 * Function to add departure and arrival marquers on the map
 * @param marker
 */
export function addMarkersDepartureAndArrivalOnTheMap(marker) {
    if (JKisio('#mapid').is(':visible')) {
        let from, to;
        marker = marker[0];
        for (let i = 0; i < marker.length; i++) {
            let sectionPos = window.Kisio.map.results.getSectionPos(i, marker.length);
            if (sectionPos.libelle === 'first') {
                if (typeof marker[i] !== 'undefined') {
                    from = marker[i].coordinates[0];
                }
            }
            if (sectionPos.libelle === 'last' || marker.length === 1) {
                if (typeof marker[i] !== 'undefined') {
                    let coordinates = marker[i].coordinates;
                    to = coordinates[coordinates.length - 1];
                }
            }
        }

        window.leaflet?.map?.setMaxZoom(window.leaflet?.map?.getZoom());

        window.Kisio.map.results.addMarkersIntoMap(from, 'from');
        window.Kisio.map.results.addMarkersIntoMap(to, 'to');

        window.leaflet?.map?.setMaxZoom(window.Kisio.map_config.max_zoom);

        return [from, to];
    }
};


export function addMarkersThroughUrl() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const searchParams = new URLSearchParams(url.search);
    const paramsArray = Array.from(searchParams.entries());
    const associativeArray = {};

    if (paramsArray.length > 0) {
        paramsArray.forEach(([key, value]) => {
            const updatedKey = key.replace(/\[\d+\]/g, '');
            associativeArray[updatedKey] = value;
        });
        const fromAutocomplete = associativeArray['search[from][autocomplete]'];
        const toAutocomplete = associativeArray['search[to][autocomplete]'];

        makeAjaxRequest(fromAutocomplete, 'from');
        makeAjaxRequest(toAutocomplete, 'to');
    }

    function makeAjaxRequest(qValue, type) {
        let searchValue;
        if(typeof qValue === 'undefined' || qValue == null) {
            return;
        }
        const typeProduct = Kisio.type_product !== undefined ? Kisio.type_product : '';
        const url = Routing.generate('r_places', { type_product: typeProduct });
        if (qValue.indexOf("(") !== -1 && qValue.indexOf(")") !== -1) {
            searchValue = qValue.substring(0, qValue.indexOf(")") + 1);
        }
        JKisio.ajax({
        url,
        data: { q: searchValue },
        dataType: 'json',
        async: true,
        success: function (result) {
            const searchKey = `search[${type}][autocomplete]`;
            const searchHiddenKey = `search[${type}][autocomplete-hidden]`;
            const place = result.places[0];

            if (place?.embedded_type === 'stop_area' && associativeArray[searchHiddenKey] == place.id) {
            manageGeoMarkers(type, associativeArray[searchKey], place.stop_area.coord, window.leaflet.map);
            }
            if (place?.embedded_type === 'address') {
            manageGeoMarkers(type, associativeArray[searchKey], place.address.coord, window.leaflet.map);
            }
        },
        error: function (error) {
            console.log(error);
        }
        });
    }
}