import {manageClickOnModes, manageDisplayedPage} from "./modules/preferences";
import { manageButtonDepartureArrival, initButtonDepartureArrival } from "./modules/buttonDepartureArrival";
import { addMarkersThroughUrl } from "./modules/map/manager-markers";

const journeyParams = {
    titleClass: 'journey_title',
    displayedContainerEvent: 'journey_container_displayed',
    closeContainerEvent: 'journey_selected_section'
};

document.addEventListener('DOMContentLoaded', function () {
    // listener on displayed journey
    document.body.addEventListener(journeyParams.displayedContainerEvent, function () {
        document.title = Translator.trans('journey.title');
        Kisio.section_active = 'journey';
        JKisio('.label-map-marker-from, .label-map-marker-to, img.leaflet-marker-icon').show();
        document.body.dispatchEvent(new CustomEvent("display_layers_section_event", {detail: {section: "journey"}}));
        manageDisplayedPage();
        manageClickOnModes();
    });

    addMarkersThroughUrl();
    
    document.body.addEventListener("departure_arrival_btn_journey", (event) => {
        manageButtonDepartureArrival(event.detail);
    });
});
