import {manageValidation}  from 'journey_bundle/modules/validator/index';


const params = {
    fromId: '#search_from_autocomplete',
    toId: '#search_to_autocomplete',
    classContent: '.kisio-result',
    formModifyId: '.journey-form-modify',
    travelerProfiles: ['traveler_type_0_0', 'traveler_type_0_1'],
    modesToUncheck: ['bike', 'bss']
};

export function manageClickOnModes() {
    JKisio('#ui-accordion-content-settings input:checkbox').on('click', function () {
        const inputId = this.id;
        const inputValue = this.value;
        if (params.travelerProfiles.includes(inputId)) {
            if (this.checked) {
                params.travelerProfiles.forEach(function (profile) {
                    if (profile !== inputId) {
                        JKisio(`#${profile}`).prop('checked', false);
                    }
                });

                disableDrawDownModes();
            } else {
                enableDrawDownModes();
            }

            JKisio('#walking_speed_0_0').prop('checked', this.checked ? true : false);
            JKisio('#walking_speed_0_1, #bike_speed_0_1').prop('checked', !this.checked ? true : false);
        }

        if (params.modesToUncheck.includes(inputValue)) {
            if (this.checked) {
                disableTravelerProfiles();
            }

            JKisio('#walking_speed_0_0').prop('checked', false);
            JKisio('#walking_speed_0_1, #bike_speed_0_1').prop('checked', true);
        }
    });
}

export function manageDisplayedPage() {
    if (!JKisio(params.fromId).val() || !JKisio(params.toId).val()) {
        JKisio(params.classContent).hide();
    }
    if (JKisio(params.classContent).length > 0 && JKisio(params.fromId).val() && JKisio(params.toId).val())
        JKisio(params.classContent).show();

    // Modify section
    if ( JKisio(params.formModifyId).is(":visible")) {
        JKisio(params.formModifyId).hide();
    }
    manageValidation();
}

function disableDrawDownModes() {
    JKisio.each(params.modesToUncheck, function(index, id) {
        JKisio(`#transport-modes input[value="${id}"]`).prop('checked', false);
    });
}

function disableTravelerProfiles() {
    JKisio.each(params.travelerProfiles, function(index, id) {
        JKisio(`#${id}`).prop('checked', false);
    });
}

function enableDrawDownModes() {
    JKisio.each(params.modesToUncheck, function(index, id) {
        JKisio(`#transport-modes input[value="${id}"]`).prop('checked', true);
    });
}