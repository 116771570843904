export function manageValidation() {
    const formSubmitSelector = '#journey-form-submit [type="submit"], #journey-form-new-search [type="submit"]';

    var form = document.getElementById("journey-target-form");

    if(typeof form === 'undefined' || form == null
        || form?.querySelector(formSubmitSelector) == null
        || form?.querySelector(formSubmitSelector)?.length === 0 ) {
        return;
    }

    form?.addEventListener('submit', (event) => {
        const isValid = validator(form, formSubmitSelector);
        if (isValid === false) {
            event.preventDefault();
            form.querySelector(formSubmitSelector).dispatchEvent(new Event('showErrorMessage'));
        }
    });
}

function validator(form) {
    const listInput = ["search_from_autocomplete", "search_to_autocomplete"];
    const suffix = "-hidden";
    const isNativFormValid = form.reportValidity();

    if(!isNativFormValid) { // nativ error is already here. just return
        return;
    }

    let isValid = true;
    listInput.forEach((inputId) => {
        let hiddenInputId = inputId + suffix;
        let inputElement = document.getElementById(inputId);
        if(document.getElementById(hiddenInputId).value == '') {
            isValid = false;
            inputElement.setCustomValidity('smth');
            inputElement.dataset.errormessage = 'journeys.' + inputElement.id.split(/_/gi)[1] + '.errors.autocomplete';
        } else {
            inputElement.setCustomValidity("");
        }
    });

    return isValid;
}
